<template>
  <div>
    <div
      class="m-auto block pb-2"
      style="max-width: 439px;"
    >
      <h2
        class="color-text-secondary font-weight-bolder"
        style="font-weight: 700; font-size: 32px"
      >
        Add project<span class="text-primary">.</span>
      </h2>
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col
              md="12"
              class="mt-1"
            >
              <b-form-group
                label-for="projectName"
                class="color-text-grey"
                style="font-weight: 700;"
              >
                <template
                  v-slot:label
                >
                  <span style="font-size: 15px; color: #656B85; font-weight: 700;">Project name</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Project name"
                  rules="required|max: 64"
                >
                  <b-form-input
                    v-model="projectName"
                    class="mt-1 "
                    placeholder="Project name"
                    size="lg"
                    :state="errors.length > 0 ? false:null"
                    style="height: 52px;"
                  />
                  <small class="text-danger ">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              md="12"
              class="d-flex my-1"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="flat-secondary"
                class="d-flex p-50"
                style="width: 140px; height: 30px;"
                @click="viewPalette ? viewPalette = false : viewPalette = true"
              >
                <div
                  :style="`width: 15px; height: 15px; background-color: ${projectColor}; border-radius: 5px;`"
                />
                <span
                  class="align-middle pl-50"
                  style="font-weight: 700; font-size: 15px; color: #656B85;"
                >Set color</span>
                <feather-icon
                  icon="ChevronRightIcon"
                  class="ml-50"
                />
              </b-button>
              <v-swatches
                v-if="viewPalette"
                v-model="projectColor"
                popover-x="left"
                swatch-size="20"
                :spacing-size="1"
                inline
                class="ml-1"
              />
            </b-col>
            <div style="background: #f0f0f0; border: 1px solid #c1c1c1; border-radius: 10px;" class="w-100 mx-1">
              <b-col
                md="12"
                class="mt-2"
              >
                <b-form-group
                  label-for="siteName"
                  class=""
                  style="font-weight: 700;"
                >
                  <template v-slot:label>
                    <span
                      style="font-weight: 700; font-size: 15px; color: #656B85;"
                      class=""
                    >Add your first site</span><span
                      style="color: #b5b5b5"
                      class="ml-50"
                    >(optional)</span>
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    name="Site name"
                    :rules="siteName || projectSites ? 'required|max: 72' : ''"
                  >
                    <b-form-input
                      v-model="siteName"
                      class="mt-1"
                      placeholder="Write you site name"
                      size="lg"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                md="12"
                class="mt-2"
              >
                <b-form-group
                  label-for="input-valid"
                  class="color-text-grey"
                  description="You can add more sites later."
                  style="font-weight: 400;"
                >
                  <template
                    v-slot:label
                  >
                    <span
                      style="font-size: 15px; color: #656B85; font-weight: 400;"
                    >Homepage URL</span>
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    name="URL"
                    :rules="siteName || projectSites ? 'required|url' : 'url'"
                  >
                    <b-form-input
                      id="input-valid"
                      v-model="projectSites"
                      :state="errors.length > 0 ? false:true"
                      placeholder="Add your URL"
                      style="height: 52px;"
                      class="mt-1 "
                    />
                    <small class="text-danger ">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </div>
            <!-- <b-col md="12">
              <validation-observer ref="simpleRulesInvitation">
                <b-form @submit.prevent>
                  <b-row
                    class="mt-1"
                    cols="12"
                  >
                    <b-col md="9">
                      <b-form-group
                        label-for="projectInvitations"
                        class="color-text-grey"
                        style="font-weight: 700;"
                      >
                        <template
                          v-slot:label
                          class=""
                        >
                          <span style="font-weight: 700; font-size: 15px; color: #656B85;">Invite team members</span><span
                            style="color: #b5b5b5"
                            class="ml-50"
                          >(optional)</span>
                        </template>
                        <validation-provider
                          #default="{ errors }"
                          name="Invite team members"
                          rules="email"
                        >
                          <vue-autosuggest
                            v-model="projectInvitation"
                            :suggestions="filteredOptions"
                            :input-props="{
                              id: 'autosuggest__input',
                              class: 'form-control',
                              placeholder: 'Add member...',                            
                            }"
                            :limit="10"
                            :state="errors.length > 0 ? false:null"
                            class="mt-1  sugg"
                            @input="onInputChange"
                            @click="onInputChange"
                            @selected="selectHandler"
                          >
                            <template slot-scope="{suggestion}">
                              <div class="d-flex align-items-center">
                                <b-avatar
                                  :src="suggestion.item.foto ? suggestion.item.foto : ''"
                                  :text="avatarText(suggestion.item.name ? suggestion.item.name : '')"
                                  style="background: #ddd !important;"
                                />
                                <div class="detail ml-50 ">
                                  <b-card-text class="mb-0">
                                    {{ suggestion.item.name }}
                                  </b-card-text>
                                  <small class="text-muted">
                                    {{ suggestion.item.email }}
                                  </small>
                                </div>
                              </div>
                            </template>
                          </vue-autosuggest>
                          <small class="text-danger ">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="3">
                      <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        :variant="requireAddEmail ? 'primary' : 'outline-secondary'"
                        style="height: 42px; margin-top: 35px;"
                        @click.prevent="validationFormInvitation"
                      >
                        ADD
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>
            </b-col> -->
            <b-col cols="12">
              <!-- <b-list-group
                flush
                class="w-100"
              >
                <b-list-group-item
                  v-for="(project, index) in projectInvitations"
                  :key="index"
                  style="display: flex;"
                  class="pl-0"
                >
                  <div class="demo-inline-spacing mt-0">
                    <b-avatar
                      variant="info"
                      style="background: #ddd !important;"
                      class="mt-0"
                      :src="project.foto"
                    >
                      <feather-icon
                        v-if="!project.foto"
                        icon="UserIcon"
                        size="16"
                      />
                    </b-avatar>
                    <div class="ml-25 mt-0">
                      <p
                        class="mb-0 "
                        style="font-size: 14px; line-height: 10px;"
                      >
                        {{ project.name }}
                      </p>
                      <span
                        class="user-status "
                        style="color: #A7AAB9;"
                      >{{ project.email }}</span>
                    </div>
                  </div>
                  <v-select
                    v-model="project.permission"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="permissionsTeam"
                    :clearable="false"
                    class="float-right style-chooser mr-3"
                    style="width: 140px; border: none; position: absolute !important; right: 0px;"
                  />
                  <b-button
                    variant="flat-primary"
                    class="btn-icon"
                    @click.prevent="deleteMember(project)"
                    style="position: absolute !important; right: 0px;"
                  >
                    <feather-icon icon="XIcon" />
                  </b-button>
                </b-list-group-item>
              </b-list-group> -->

              <b-button
                variant="primary"
                type="submit"
                :disabled="busy ? true : requireAddEmail ? true : false"
                class="mt-2"
                @click.prevent="validationForm"
              >
                Create Project
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BCardText, BAvatar, BListGroup, BListGroupItem, BLink, BImg, BInputGroup, BInputGroupPrepend, BInputGroupAppend, BFormTextarea,
  BButton, BModal, VBModal, BForm, BFormInput, BFormGroup, VBTooltip, BImgLazy, BFormTags, BFormValidFeedback,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, url, email, max,
} from '@validations'
import { heightTransition } from '@core/mixins/ui/transition'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import VSwatches from 'vue-swatches'
import 'vue-swatches/dist/vue-swatches.css'
import { VueAutosuggest } from 'vue-autosuggest'
// import Urlbox from 'urlbox'
import { mapActions, mapState } from 'vuex'
import { db, auth, firebase } from '@/firebase'

export default {
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BAvatar,
    BListGroup,
    BListGroupItem,
    BLink,
    BImg,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormTextarea,
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BImgLazy,
    BFormTags,
    BFormValidFeedback,

    VBModal,
    VSwatches,
    vSelect,
    VueAutosuggest,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [heightTransition],
  data() {
    return {
      // config page
      userData: '',
      required,
      url,
      email,
      max,
      busy: false,

      // form fields
      projectName: '',
      projectColor: '#a463bf',
      projectSites: '',
      siteName: '',
      projectInvitation: '',
      projectInvitations: [],
      objInvitation: '',
      permission: { title: 'Viewer' },

      // functions repeater
      nextTodoId: 2,
      viewPalette: false,
      permissionsTeam: [{ title: 'Viewer' }, { title: 'Editor' }, { title: 'Admin/editor' }],

      // suggest select
      datasuggest: [],
      datasuggestSort: [],
      sortedComplete: false,
      totalUserMember: 0,
      filteredOptions: [],
      inputProps: {
        id: 'autosuggest__input',
        class: 'form-control',
        placeholder: 'Add member...',
      },
      limit: 10,
      selected: null,
      sectionConfigs: {
        destinations: {
          limit: 6,
          label: 'Destination',
          onSelected: selected => {
            this.selected = selected.item
          },
        },
        hotels: {
          limit: 6,
          label: 'Hotels',
          onSelected: selected => {
            this.selected = selected.item
          },
        },
      },
      allProjects: [],
      requireAddEmail: false,
    }
  },
  watch: {
    projectSites(val) {
      val.includes('http') || val.includes('ht') ? this.projectSites = val : this.projectSites = `https://${this.projectSites}`
    },
    projectColor(val) {
      if(this.viewPalette) {
        this.viewPalette = false
      } 
    }
  },
  methods: {
    ...mapActions(['createProject']),
    deleteMember(val) {
      // console.log(val)
      this.projectInvitations = this.projectInvitations.filter(ele => ele.email !== val.email)
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if(success) {
          this.busy = true

          db.collection('Projects').where('name', '==', this.projectName).where('owner.uid', '==', this.userData.uid).get()
          .then(existProject => {
            if(existProject.size >= 1) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Choose a different name.',
                  icon: 'CoffeeIcon',
                  variant: 'warning',
                },
              })
            } else {
              this.submitProject()
            }
          })
          .catch(error => console.log(error))
        }
      })
    },
    validationFormInvitation() {
      this.$refs.simpleRulesInvitation.validate().then(success => {
        if(success) {
          const user = auth.currentUser
          // console.log(user.email,this.projectInvitation.trim())
          if(user.email === this.projectInvitation.trim()) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `${this.projectInvitation} is already the owner of this project.`,
                icon: 'CoffeeIcon',
                variant: 'danger',
              },
            })
            this.projectInvitation = ''
            this.objInvitation = ''
            this.onInputChange()
          } else {
            if(this.projectInvitation) {
              const filterEmail = this.projectInvitations.filter(item => item.email === this.projectInvitation)
              if(this.projectInvitations.length === 0 || filterEmail.length === 0) {
                this.projectInvitations.push({
                  email: this.projectInvitation, permission: this.permission.title, foto: this.objInvitation.foto ? this.objInvitation.foto : '', name: this.objInvitation.name ? this.objInvitation.name : '',
                })
                this.projectInvitation = ''
                this.objInvitation = ''
                this.onInputChange()
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: `${this.projectInvitation} is already a team member.`,
                    icon: 'CoffeeIcon',
                    variant: 'danger',
                  },
                })
                this.projectInvitation = ''
                this.objInvitation = ''
                this.onInputChange()
              }
            }
          }
        }
      })
    },
    submitProject() {
      // console.log({projectName: this.projectName, projectColor: this.projectColor, projectSites: this.projectSites, projectInvitations: this.projectInvitations, owned: this.userData})
      this.busy = true
      if(this.projectSites) {
        this.successValidationCreate()
      } else {
        // this.busy = false
        this.successValidationCreate()
      }
    },
    successValidationCreate() {
      // console.log('SUCCESS FORM')
      // const urlbox = Urlbox('iWVAo1U5wUbLhzWJ', 'c3e32765daba406cb27d1ad22ed93b6d');
      
      if(this.projectSites) {
        // Set your options
        // const options = {
        //   url: this.projectSites,
        //   thumb_width: 1440,
        //   // height: 140,
        //   format: "jpg",
        //   quality: 70,
        //   delay: 500
        // };
        
        // const urlImgUrl = urlbox.buildUrl(options)

        // var obj = {
        //   url: urlImgUrl
        // }
        // var config = {
        //   method: 'POST',
        //   url: 'https://luguzxy83e.execute-api.us-east-2.amazonaws.com/prod/imgseocloud',
        //   headers: {
        //     'Content-Type': 'application/json',
        //   },
        //   data: JSON.stringify(obj),
        // }

        // this.axios(config)
        // .then(async response => {
        //   const refImg = await storage.ref().child('sitesImgs').child(`${Date.now()}siteImg`)
        //   const upl = await refImg.putString(response.data, 'data_url')
        //   const urlDescarga = await refImg.getDownloadURL()
        //   // this.imgSite = urlDescarga
        //   this.createProject({
        //     projectName: this.projectName, projectColor: this.projectColor, siteName: this.siteName, projectSites: this.projectSites, projectInvitations: this.projectInvitations, owned: this.userData, thumbnail: urlDescarga
        //   })
        // })
        // .catch(() => {
          this.createProject({
            projectName: this.projectName, projectColor: this.projectColor, siteName: this.siteName, projectSites: this.projectSites, projectInvitations: this.projectInvitations, owned: this.userData, thumbnail: ''
          })
        // })
      } else {
        this.createProject({
          projectName: this.projectName, projectColor: this.projectColor, siteName: this.siteName, projectSites: this.projectSites, projectInvitations: this.projectInvitations, owned: this.userData, thumbnail: ''
        })
      }
      
      setTimeout(() => {
        this.busy = false
      }, 5000)
    },
    selectHandler(val) {
      // console.log(val.item)
      this.projectInvitation = val.item.email
      this.objInvitation = val.item
      // projectInvitation
      this.onInputChange()
    },
    onInputChange(text) {
      var emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
      var validMail = emailRegex.test(text)
      var validMailvModel = emailRegex.test(this.projectInvitation)
      // console.log(text,validMail,this.projectInvitation)
      if(validMail && text) {
        this.requireAddEmail = true
      } else if(validMailvModel && this.projectInvitation) {
        this.requireAddEmail = true
      } else {
        this.requireAddEmail = false
      }
      // console.log(validMail)

      this.datasuggest = []
      this.datasuggestSort.sort(function (a, b) {
        if(a.name > b.name) {
          return 1;
        }
        if(a.name < b.name) {
          return -1;
        }
        return 0;
      });
      this.datasuggestSort.forEach(item => {
        // console.log(item.name)
        this.datasuggest.push(item)
      })

      this.projectInvitations.forEach(item => {
        this.datasuggest = this.datasuggest.filter(ele => ele.email !== item.email)
      })

      if(!text) {
        const filteredData = this.datasuggest
        this.filteredOptions = [{
          data: filteredData,
        }]
      } else {
        const filteredDevelopers = this.datasuggest.filter(item => item.email.toLowerCase().indexOf(text.toLowerCase()) > -1 || item.name.toLowerCase().indexOf(text.toLowerCase()) > -1).slice(0, this.limit)
        const filteredData = filteredDevelopers
        this.filteredOptions = [{
          data: filteredData,
        }]
      }
    },
  },
  setup() {
    return {
      avatarText,
    }
  },
  mounted() {
    // this.repeateAgain()
    // this.initTrHeight()
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  created() {
    const aleatColor = ['#1FBC9C', '#1CA085', '#2ECC70', '#27AF60', '#3398DB', '#2980B9', '#A463BF', '#8E43AD', '#3D556E', '#222F3D', '#F2C511', '#F39C19', '#E84B3C', '#C0382B', '#DDE6E8', '#BDC3C8']
    const rand = Math.floor(Math.random() * aleatColor.length)
    this.projectColor = aleatColor[rand]
    // window.addEventListener('resize', this.initTrHeight)
    const user = auth.currentUser
    if(user) {
      // db.collection('Users').doc(user.uid).get()
      // .then(userDoc => {
      //   this.userData = userDoc.data()
      //   if (userDoc.data().teams && userDoc.data().teams.length >= 1) {
      //     userDoc.data().teams.forEach(element => {
      //       db.collection('projects').doc(element).get()
      //         .then(project => {
      //           if (project.data().invitations && project.data().invitations.length >= 1) {
      //             project.data().invitations.forEach(item => {
      //               db.collection('Users').where('email', '==', item).get()
      //                 .then(queryUs => {
      //                   if (queryUs.size >= 1) {
      //                     queryUs.forEach(que => {
      //                       if (user.uid !== que.data().uid) {
      //                         this.datasuggest.push({ name: que.data().nombre, email: que.data().email, foto: que.data().foto })
      //                       }
      //                     })
      //                   }
      //                 })
      //             })
      //           }
      //         })
      //     })
      //     // this.datasuggest.push({name: user.data().nombre, email: user.data().email, foto: user.data().foto})
      //   }
      // })

      db.collection('Users').doc(user.uid).get()
      .then(userDoc => {
        this.userData = {}
        this.userData.uid = userDoc.id
        this.userData.foto = userDoc.data().foto ? userDoc.data().foto : ''
        this.userData.name = userDoc.data().nombre ? userDoc.data().nombre : ''
        this.userData.status = userDoc.data().status ? userDoc.data().status : ''
        this.userData.email = userDoc.data().email ? userDoc.data().email : ''
        
        // if(userDoc.data().teams && userDoc.data().teams.length >= 1) {
        //   userDoc.data().teams.forEach(element => {
        //     db.collection('projects').doc(element).collection('members').where('uid', '!=', 'resume').get()
        //     .then(members => {
        //       this.datasuggest = []
        //       this.datasuggestSort = []
        //       members.forEach(ele => {
        //         if (user.uid !== ele.data().uid) {
        //           if (ele.id !== 'resume') {
        //             if (ele.data().uid) {
        //               db.collection('Users').doc(ele.data().uid).get()
        //                 .then(docUs => {
        //                   // console.log(docUs.data().teams.includes(this.$route.params.id))
        //                   // if(!docUs.data().teams.includes(this.$route.params.id ? this.$route.params.id : this.idsite.idsite)) {
        //                     const filtMem = this.datasuggestSort.filter(mem => mem.email === ele.data().email)
        //                     if (filtMem.length === 0) {
        //                       this.totalUserMember += 1
        //                       this.datasuggestSort.push({ name: docUs.data().nombre ? docUs.data().nombre : '', email: docUs.data().email, foto: docUs.data().foto ? docUs.data().foto : '' })
        //                     }
        //                   // }
        //                 })
        //                 .catch(error => console.log(error))
        //             } else {
        //               const filtMem = this.datasuggestSort.filter(mem => mem.email === ele.data().email)
        //               if (filtMem.length === 0) {
        //                 this.totalUserMember += 1
        //                 this.datasuggestSort.push({ name: ele.data().email, email: ele.data().email, foto: '' })
        //               }
        //             }
        //           }
        //         }
        //       })
        //       // this.loader = false
        //     })
        //     .catch(error => console.log(error))
        //   })

          
        //   userDoc.data().teams.forEach(element => {
        //     db.collection('projects').doc(element).get()
        //     .then(project => {
        //       // db.collection('projects').doc(element).collection('members').where('uid', '==', user.uid).get()
        //       // .then(invitationOwn => {
        //         // db.collection('sites').where('project', '==', element).get()
        //         // .then(sites => {
        //           // if(sites.size >= 1) {
        //             // var existProjectSite = false
        //             // sites.forEach(site => {
        //             //     if(site.data().project) {
        //             //         existProjectSite = true
        //             //     }
        //             // })

        //             // let permUser = ''
        //             // if (invitationOwn.size >= 1) {
        //             //   invitationOwn.forEach(inv => {
        //             //     // console.log(inv.data())
        //             //     permUser = inv.data().perm
        //             //   })
        //             // }

        //             // if (invitationOwn.size >= 1 || project.data().owned.uid === user.uid) {
        //             //   if(project.data().owned.uid === user.uid || permUser === 'edit' || permUser === 'admin') {
        //             //     this.arrayProjects.push({
        //             //       title: project.data().name,
        //             //       icon: project.data().color,
        //             //       uid: project.id,
        //             //     })
        //             //   }
        //             // }
        //             this.allProjects.push({
        //               uid: project.id,
        //               shared: project.data().owned.uid === user.uid ? true : false
        //             })
        //           // }
        //         // })
        //       // })
        //     })
        //     .catch(error => console.log(error))
        //   })
        // }
      })
      .catch(error => console.log(error))
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style>
.form-control.is-valid {
    border-color: #d8d6de;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='gray' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
}
.form-control.is-valid:focus {
    border-color: #d8d6de;
}
.vue-swatches__trigger {
    height: 30px !important;
    width: 30px !important;
}
.tags-projects-invitation {
    height: 160px !important;
}

.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  background: #fff;
  border: none;
  /* color: #394066; */
  /* text-transform: lowercase; */
  /* font-variant: small-caps; */
}

.style-chooser .vs__clear,
.style-chooser .vs__open-indicator {
  fill: #394066;
}

.b-form-tag {
    background-color: #eff0f2;
    color: #81838b;
}
.b-form-tags-button {
    display: none;
}

.demo {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

/* input {
  width: 260px;
  padding: 0.5rem;
} */

.autosuggest__results > ul {
  width: 100%;
  color: rgba(30, 39, 46,1.0);
  list-style: none;
  margin: 0;
  padding: 0.5rem 0 .5rem 0;
}
.autosuggest__results > li {
  margin: 0 0 0 0;
  border-radius: 5px;
  padding: 0.75rem 0 0.75rem 0.75rem;
  display: flex;
  align-items: center;
}
li:hover {
  cursor: pointer;
}

.autosuggest-container {
  display: flex;
  justify-content: center;
  width: 280px;
}

#autosuggest { width: 100%; display: block;}
.autosuggest__results-item--highlighted {
  background-color: rgba(51, 217, 178,0.2);
}
#autosuggest__input {
    height: 42px;
}
</style>
